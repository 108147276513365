<template>
    <div class="full-height-layout fhf pb-10">
        <mercur-card class="ml-0 mr-0 fhf u-no-horizontal-overflow">
            <h2 class="font-weight-normal">Manual artwork check: {{ configuration.missingManualArtworkConfigurationAmount }} configurations of {{ configuration.productConfigurationName }}</h2>

            <div class="artwork-management artwork-management--manual-check">
                <table class="mt-20 artwork-management-table">
                    <tr class="artwork-management-table__header">
                        <th class="artwork-management-table__header-cell">INTERNAL ARTWORK NAME</th>
                        <th class="artwork-management-table__header-cell u-text-center">Manual Artwork check grade</th>
                        <th class="artwork-management-table__header-cell u-text-center">Pagecount Check</th>
                        <th class="artwork-management-table__header-cell u-text-center">Contourcut Check</th>
                        <th class="artwork-management-table__header-cell u-text-center">Vector only</th>
                        <th class="artwork-management-table__header-cell artwork-management-table__header-cell--w-pixels-90 u-text-center">Transparency</th>
                        <th class="artwork-management-table__header-cell artwork-management-table__header-cell--w-pixels-90 u-text-center">Minimum DPI</th>
                        <th class="artwork-management-table__header-cell artwork-management-table__header-cell--w-pixels-90 u-text-center">Bleed <br>(in mm)</th>
                        <th class="artwork-management-table__header-cell artwork-management-table__header-cell--w-pixels-90 u-text-center">Safe margin <br>(in mm)</th>
                        <th class="artwork-management-table__header-cell u-text-center">&nbsp;</th>
                    </tr>

                    <tr class="filters-row">
                        <td class="filters-row__cell">
                            <div class="artwork-management-table__cell-content-centered">
                                <mercur-input class="filters-row__search w-100" v-model="artworkNameSearch" placeholder="Search name"></mercur-input>
                                <i class="fas fa-search artwork-management-icon-orange artwork-management-search-icon ml-2"></i>
                            </div>
                        </td>
                        <td class="filters-row__cell">
                            <mercur-select v-model="bulkEditorData.manualArtworkCheckGrade" class="artwork-management-select">
                                <template slot="label">Select a grade</template>
                                <option v-for="grade in gradeOptions" :value="grade" :key="grade"> {{ grade }}</option>
                            </mercur-select>
                        </td>
                        <td class="filters-row__cell filters-row__cell--centered">
                            <div class="artwork-management-table__cell-content-centered">
                                <mercur-checkbox v-model="bulkEditorData.pagecountCheck" color="black" class="artwork-management-checkbox artwork-management-checkbox--orange"></mercur-checkbox>
                            </div>
                        </td>
                        <td class="filters-row__cell filters-row__cell--centered">
                            <div class="artwork-management-table__cell-content-centered">
                                <mercur-checkbox v-model="bulkEditorData.contourcutCheck" color="black" class="artwork-management-checkbox artwork-management-checkbox--orange"></mercur-checkbox>
                            </div>
                        </td>
                        <td class="filters-row__cell filters-row__cell--centered">
                            <div class="artwork-management-table__cell-content-centered">
                                <mercur-checkbox v-model="bulkEditorData.vectorOnly" color="black" class="artwork-management-checkbox artwork-management-checkbox--orange"></mercur-checkbox>
                            </div>
                        </td>
                        <td class="filters-row__cell filters-row__cell--centered">
                            <div class="artwork-management-table__cell-content-centered">
                                <mercur-checkbox v-model="bulkEditorData.transparency" color="black" class="artwork-management-checkbox artwork-management-checkbox--orange"></mercur-checkbox>
                            </div>
                        </td>
                        <td class="filters-row__cell filters-row__cell--centered">
                            <input class="artwork-management-table__cell-input artwork-management-table__cell-input--highlight" v-model="bulkEditorData.minimumDPI" />
                        </td>
                        <td class="filters-row__cell filters-row__cell--centered">
                            <input class="artwork-management-table__cell-input artwork-management-table__cell-input--highlight" v-model="bulkEditorData.bleed" />
                        </td>
                        <td class="filters-row__cell filters-row__cell--centered">
                            <input class="artwork-management-table__cell-input artwork-management-table__cell-input--highlight" v-model="bulkEditorData.safeMargin" />
                        </td>
                        <td class="filters-row__cell filters-row__cell--centered">
                            <mercur-button
                                @click.native="applyOptionsToAll"
                                :disabled="isSavingArtworkConfigurationSettingsByInternalArtworkName || applyToAllBtnDisabled"
                                class="btn btn-icon artwork-management-btn-icon">
                                <i class="fas fa-arrow-alt-circle-down artwork-management-icon-orange artwork-management-apply-icon"></i>
                            </mercur-button>
                        </td>
                    </tr>

                    <tr class="artwork-management-table__row" v-for="item in filteredItems" :key="item.internalArtworkName">
                        <td class="artwork-management-table__cell text-artwork-blue">{{ item.internalArtworkName }}</td>
                        <td class="artwork-management-table__cell">
                            <mercur-select v-model="item.manualCheckConfig.manualArtworkCheckGrade">
                                <template slot="label">Select a grade</template>
                                <option v-for="grade in gradeOptions" :value="grade" :key="grade"> {{ grade }}</option>
                            </mercur-select>
                        </td>
                        <td class="artwork-management-table__cell u-text-center">
                            <div class="artwork-management-table__cell-content-centered">
                                <mercur-checkbox v-model="item.manualCheckConfig.pagecountCheck" class="artwork-management-checkbox"></mercur-checkbox>
                            </div>
                        </td>
                        <td class="artwork-management-table__cell u-text-center">
                            <div class="artwork-management-table__cell-content-centered">
                                <mercur-checkbox v-model="item.manualCheckConfig.contourcutCheck" class="artwork-management-checkbox"></mercur-checkbox>
                            </div>
                        </td>
                        <td class="artwork-management-table__cell u-text-center">
                            <div class="artwork-management-table__cell-content-centered">
                                <mercur-checkbox v-model="item.manualCheckConfig.vectorOnly" class="artwork-management-checkbox"></mercur-checkbox>
                            </div>
                        </td>
                        <td class="artwork-management-table__cell u-text-center">
                            <mercur-checkbox v-model="item.manualCheckConfig.transparency" class="artwork-management-checkbox"></mercur-checkbox>
                        </td>
                        <td class="artwork-management-table__cell u-text-center"><input class="artwork-management-table__cell-input" v-model="item.manualCheckConfig.minimumDPI" /></td>
                        <td class="artwork-management-table__cell u-text-center"><input class="artwork-management-table__cell-input" v-model="item.manualCheckConfig.bleed" /></td>
                        <td class="artwork-management-table__cell u-text-center"><input class="artwork-management-table__cell-input" v-model="item.manualCheckConfig.safeMargin" /></td>
                        <td class="artwork-management-table__cell">&nbsp;</td>
                    </tr>
                </table>
            </div>
        </mercur-card>
        <mercur-card class="mt-15 ml-0 mr-0 text-right">
            <mercur-button
                :disabled="isSavingConfiguration || !!promises.length"
                @click.prevent="goToNextStep"
                class="btn btn-raised btn-yellow text-uppercase">
                <span>Save + Continue</span>
                <i class="fas fa-forward artwork-management-save-icon ml-1"></i>
            </mercur-button>
        </mercur-card>
    </div>
</template>

<script>
import ArtworkManagementConfigurationsMixin from '@/views/artwork_management/configurations/ArtworkManagementConfigurationsMixin'

const defaultBulkEditorData = {
    manualArtworkCheckGrade: '',
    pagecountCheck: false,
    contourcutCheck: false,
    vectorOnly: false,
    transparency: false,
    minimumDPI: '',
    bleed: '',
    safeMargin: '',
}

export default {
    name: 'ArtworkManagementManualCheckConfig',
    mixins: [ ArtworkManagementConfigurationsMixin ],
    data () {
        return {
            artworkNameSearch: '',
            promises: [],
            bulkEditorData: { ...defaultBulkEditorData },
            gradeOptions: [
                'Intermediate',
                'Advanced',
                'Expert',
                'Master',
                'Specialist',
            ],
        }
    },
    methods: {
        applyOptionsToAll () {
            this.filteredItems.forEach(item => {
                const index = this.configurationSettings.findIndex(e => e.internalArtworkName === item.internalArtworkName)
                this.$set(this.configurationSettings[index], 'manualCheckConfig', JSON.parse(JSON.stringify(this.bulkEditorData)))
            })

            const updatedData = { ...this.bulkEditorData }
            Object.keys(updatedData).forEach(key => {
                if (updatedData[key] === defaultBulkEditorData[key]) {
                    delete updatedData[key]
                }
            })

            const internalArtworkNames = this.filteredItems.map(item => item.internalArtworkName)
            const payload = { internalArtworkNames, data: { manualCheckConfig: { ...updatedData } } }
            this.saveArtworkConfigurationSettingsByInternalArtworkName(payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Configuration settings were updated',
                    type: 'success',
                })
            })
        },
        goToNextStep () {
            this.configurationSettings.forEach((setting, index) => {
                if (JSON.stringify(setting.manualCheckConfig) !== JSON.stringify(this.configurationSettingsInitialValues[index].manualCheckConfig)) {
                    this.promises.push(this.saveArtworkConfigurationSetting(setting))
                }
            })

            Promise.all(this.promises).then(() => {
                this.$router.push({
                    name: 'ArtworkManagementAutoCheckConfig',
                    params: {
                        ...this.params,
                    },
                })
            })
        },
    },
    computed: {
        filteredItems () {
            if (!this.configurationSettings) {
                return []
            }

            return this.configurationSettings.filter(item => item.internalArtworkName.includes(this.artworkNameSearch))
        },
        applyToAllBtnDisabled () {
            return JSON.stringify(defaultBulkEditorData) === JSON.stringify(this.bulkEditorData)
        },
    },
}
</script>
